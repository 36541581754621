<script setup>
import { useForm } from '@inertiajs/vue3';

import Button from '@/Components/Button/Button.vue';
import Title from '@/Components/Title/Title.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';

// Icons
import { mapErrors } from '@/utilities';
import saveIcon from '@i/save-white.svg';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    category: Object,
});

const form = useForm({
    ...props.category,
});

const deleteForm = useForm({});

const sendForm = () => {
    form.put(route('staff.faq-categories.update', { faq_category: props.category.id }), {
        preserveState: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const deleteSubmit = () => {
    deleteForm.delete(route('staff.faq-categories.destroy', props.category.id));
};
</script>

<template>
    <StaffLayout :title="$t('Edit {model}', { model: $t('FAQ Category') })">
        <Title :title="$t('Edit {model}', { model: $t('FAQ Category') })">
            <Button
                danger
                :processing="deleteForm.processing"
                :recentlySuccessful="deleteForm.recentlySuccessful"
                @click="deleteSubmit"
                :text="$t('Delete')"
            />
        </Title>

        <div class="grid gap-x-8 gap-y-2 md:grid-cols-2">
            <FormKit
                type="text"
                name="name_nl"
                :label="$t('Name') + ' (nl)'"
                v-model="form.name_nl"
                :errors="form.errors.name_nl"
                :value="form.name_nl"
            />

            <FormKit
                type="text"
                name="name_en"
                :label="$t('Name') + ' (en)'"
                v-model="form.name_en"
                :errors="form.errors.name_en"
                :value="form.name_en"
            />

            <FormKit
                type="number"
                name="order_column"
                :label="$t('Order Column')"
                v-model="form.order_column"
                :errors="form.errors.order_column"
                :value="form.order_column"
            />
        </div>
        <div class="flex justify-end">
            <Button
                orange
                type="button"
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :text="$t('Save')"
                :icon="saveIcon"
                @click="sendForm"
            />
        </div>
    </StaffLayout>
</template>
